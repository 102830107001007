.title {
  font-weight: 700;
}

.deliveryData {
  font-size: 12px;
}

.nextOrderChangeDeadlineDate {
  font-weight: 700;

  &.deliveryStatusNone {
    color: #0096a0;
  }
  &.deliveryStatusCancel {
    color: #ff6478;
  }
  &.deliveryStatusPartlyShipped {
    color: #fac83c;
  }
  &.deliveryStatusShipped {
    color: #78b428;
  }
}
