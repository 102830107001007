.menuAccordion {
  position: fixed;
  overflow: scroll;
  width: 100vw;
  height: calc(100vh - 54px);
  top: -100vh;
  right: 0;
  z-index: 1;
  background-color: #fff;
  transition: 0.6s ease all;
}

.menuAccordionOn {
  top: 54px;
}

.menuCategory {
  display: block;
  padding: 10px 0;
}

.menuLink {
  display: block;
  padding: 0 0 6px;
  margin-left: -10px;
  position: relative;
}

.menuLinkImage {
  vertical-align: middle;
}

.menuLinkSpan {
  vertical-align: top;
  display: inline-block;
  margin-top: 6px;
}
