$base-yellow-color: #fac83c;
$base-white-color: #fff;

.product {
  &Cv {
    position: fixed;
    z-index: 10;
    display: block;
    padding-right: 14px;
    width: 100%;
    max-width: calc(1008px * 0.25);
    @media screen and (max-width: 1036px) {
      position: relative;
      padding-right: 0;
      max-width: inherit;
    }
  }

  &Tags {
    span {
      display: inline-block;
      vertical-align: top;
      background-color: #f0f0f0;
      margin-right: 3px;
      margin-bottom: 7px;
      border-radius: 4px;
      padding: 0 10px;
      font-size: 12px;
      font-weight: bold;
      line-height: 2;
    }
  }

  &Refrigerator {
    padding: 20px 40px;
    border-radius: 4px;
    @media screen and (max-width: 1036px) {
      padding: 20px;
    }
  }
}

.sectionTitle {
  padding-bottom: 10px;
  border-bottom: 2px solid $base-yellow-color;
}

.dummyProductListSlider {
  height: 128px;
}
