.root {
  @media screen and (max-width: 1036px) {
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.container {
  background-color: #fff;
  border-radius: 4px;
  padding: 40px !important;
  @media screen and (max-width: 1036px) {
    border-radius: 0;
    padding: 40px 28px !important;
    width: calc(100% - 4px);
  }
}

.highlighted {
  outline: 2px solid #ff6478;
}
