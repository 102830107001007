.annotation {
  white-space: pre-wrap;
}

.giftItem {
  flex-basis: 100%;

  @media screen and (min-width: 1036px) {
    // gap: 20px;なので
    flex-basis: calc(50% - 10px);
  }
}
