.container {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1036px) {
  .container {
    display: none;
  }
}

.side {
  display: flex;
  margin-left: auto;
}

.link {
  display: block;
}
