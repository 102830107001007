.faqs {
  border-bottom: 1px solid #c8c8c8;
}

.faqItem {
  border-top: 1px solid #c8c8c8;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
}

.faqIcon {
  position: absolute;
  right: 10px;
  top: 25px;
  font-size: 12px;
}

.faqContent {
  border-top: 1px dashed #c8c8c8;
  padding-top: 20px;
  padding-bottom: 20px;
}
