.additionItem {
  color: #78b428;
}

.subtractionItem {
  color: #ff6478;
}

.date {
  color: #828282;
}

.description {
  font-weight: 700;
}

.amount {
  font-weight: 700;
  font-size: 24px;
  text-align: right;
}

.label {
  font-weight: 700;
  text-align: right;
}
