.hr {
  border-bottom-color: #c8c8c8;
}

.dashed {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}

.noMargin {
  margin: 0 !important;
}
