.root {
  width: fit-content;
  gap: 10px;
  align-items: center;
}
.konbini__block {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
}
.konbini__image__padding {
  padding-right: 5px;
}
@media screen and (max-width: 767px) {
  .konbini__block {
    position: relative;
    display: block;
    margin-top: 10px;
  }
}
