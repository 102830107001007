.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  position: relative;
  background-color: #00508c;
}

.inner {
  // PC
  @media (min-width: 1036px) {
    max-width: 800px;
  }

  // Mobile
  @media screen and (max-width: 1035px) {
    width: 100%;
    padding: 0 28px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > h3 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 700;
  }
  & > h4 {
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
  }
}

.body {
  padding: 40px 0;
  // PC
  @media (min-width: 1036px) {
    display: flex;
    flex-direction: row;
    gap: 28px;
    align-items: start;
    justify-content: center;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
}

.paper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  gap: 20px;
  border-radius: 10px;

  // PC
  @media (min-width: 1036px) {
    width: 320px;
  }

  // Mobile
  @media screen and (max-width: 1035px) {
    width: 100%;
  }
}

.headerImage {
  width: 120px;
  height: 120px;
}

.rankRateImage {
  max-width: 90%;
  height: auto;
  margin-top: 10px;
}

.mileAmount {
  font-size: 40px;
  margin-right: 2px;
  letter-spacing: 0.02em;
}

.policyLink {
  display: block;
  color: #ffffff !important;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline !important;
}
