.fullImages {
  width: 100%;
  height: auto;
}
.image {
  display: inline-block;
  vertical-align: middle;
}
.timingFlex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.timingFlex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.timing {
  width: 140px;
  height: 140px;
  border-radius: 140px;
  background-color: #fac83c;
  padding: 46px 20px;
  margin: 0 10px 20px;
}
.timing:last-child {
  padding: 40px 20px;
}
@media screen and (max-width: 376px) {
  .timing {
    width: calc(42vw - 20px);
    height: calc(42vw - 20px);
  }
}
