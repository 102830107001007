.wrapper {
  @media screen and (min-width: 1036px) {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.section {
  border-radius: 20px;
  background-color: #fff;
  padding: 30px 20px;

  @media screen and (min-width: 1036px) {
    min-height: 310px;
  }
}

.image {
  height: 100px;
  width: auto;
  display: block;
  margin: auto;
}

.outline {
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
}

.percent {
  display: inline-block;
  margin-right: 5px;
  padding: 3px 6px;
  vertical-align: text-bottom;
}
