$normalColor: #fac83c;
$freezeColor: #8cb4dc;

.container {
  border-top: 1px solid #e8e9eb;
}

.normalBorder {
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid $normalColor;
}
.freezeBorder {
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid $freezeColor;
}

.normal {
  background-color: $normalColor;
}

.freeze {
  background-color: $freezeColor;
}

.title {
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 20px;
}

.validation {
  width: 50%;
  margin-left: 50%;
}

@media screen and (max-width: 1036px) {
  .validation {
    width: 100%;
    margin-left: 0;
  }
}

.error {
  background-color: #00508c;
  border-radius: 4px;
  padding: 15px 10px;
  width: 100%;
}

.subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-left: 50%;
}
