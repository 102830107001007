.faqSectionTitle {
  padding: 9px 10px;
}
.faqSet {
  border-bottom: 1px solid #c8c8c8;
  &:nth-child(2) {
    border-top: 1px solid #c8c8c8;
  }
}
.faqQuestion {
  padding-right: 3em;
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    display: block;
    background: url(https://asset.basefood.co.jp/images/navi/icon-plus.png) no-repeat 50%;
    background-size: contain;
  }
  &.open {
    &:after {
      background: url(https://asset.basefood.co.jp/images/navi/icon-minus.png) no-repeat 50%;
      background-size: contain;
    }
  }
}
.faqAnswer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  white-space: pre-wrap;
  &.open {
    max-height: 400px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px dashed #c8c8c8;
  }
}
