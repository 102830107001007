.giftsTradesSection {
  margin-top: 20px;
  @media screen and (max-width: 1035px) {
    padding: 0 20px;
  }
}

.title {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  font-weight: 700;
  background-color: #fac83c;
  border-radius: 4px 4px 0 0;
}

.container {
  padding: 20px;
  background-color: #ffffff;
  border: solid 1px #fac83c;
  border-radius: 0 0 4px 4px;
  @media screen and (min-width: 1036px) {
    padding: 40px;
  }
}

.annotation {
  color: #828282;
  font-size: 12px;
}

.giftsTradeItem {
  flex-basis: 100%;

  @media screen and (min-width: 1036px) {
    // gap: 20px;なので
    flex-basis: calc(50% - 10px);
  }
}
