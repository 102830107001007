@import "../../../style/flex";

.root {
  display: flex;
}

.normal {
  flex-direction: row;
}

.reverse {
  flex-direction: row-reverse;
}
