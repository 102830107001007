.container {
  width: 100%;
}

.description {
  &Text {
    white-space: pre-wrap;
    a {
      color: #fac83c !important;
      border-bottom: 1px solid;
    }
  }
}

.accordion {
  border: 0;
}

.questions {
  padding: 15px 0;
}
