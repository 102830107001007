.circles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1036px) {
  .circles {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.circle {
  width: calc(50% - 20px);
  margin: 30px 10px !important; // p:last-childでの上書きを防ぐ
  padding: 20px;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1036px) {
  .circle {
    width: 150px;
    margin: 10px;
    padding: 20px;
    position: relative;
    z-index: 1;
  }
}

.circle:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 140px;
  height: 140px;
  background-color: #fac83c;
  border-radius: 50%;
  z-index: -1;
}
