.root {
  position: relative;
  width: 100%;
}

.filter {
  filter: blur(8px);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.none {
  display: none;
}
