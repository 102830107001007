.proteinReason {
  border-radius: 10px;
  padding: 40px;
}
.proteinGift {
  display: table;
  width: 100%;
}
.proteinQaBtn {
  background: transparent;
}

.image {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 980px) {
  .proteinReason {
    padding: 30px 20px;
  }
}
