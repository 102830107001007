.question {
  -webkit-align-items: center;
  align-items: center;
}

.graph {
  border-radius: 20px;
  background-color: #fff;
  padding: 30px 20px;
}
