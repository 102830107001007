.ingredientsTable {
  width: 100%;

  td {
    padding: 10px 0;
  }
}
.ingredientsBorderBottomSolid {
  border-bottom: 1px solid #c8c8c8;
}
.ingredientsBorderBottomDotted {
  border-bottom: 1px dotted #c8c8c8;
}
