.infoBlock {
  padding: 20px;
  border-radius: 10px;
}
.appInfo {
  display: flex;
  align-items: center;
  width: 100%;
  dt {
    width: 80px;
  }
}
.basefoodIcon {
  border-radius: 13.477px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
.bottomDashedLine {
  padding-bottom: 20px;
  border-bottom: 1px dashed #5a5a5a;
  margin-bottom: 20px;
  &.text__white {
    border-color: #fff;
  }
}
.recommendReason {
  position: relative;
  padding-left: 35px;
  padding-bottom: 5px;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    background-color: #fac83c;
    border-radius: 50%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 7px;
    height: 11px;
    transform: rotate(40deg);
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
  }
}
