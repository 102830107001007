.wrapper {
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.05);
}

.inner {
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 750px;

  &:hover {
    cursor: pointer;
    // global styleを上書きするため!importantを使用
    opacity: 1 !important;
  }
}

.innerPaddingBottom {
  padding-bottom: 9px;
}

.icon {
  margin-left: 5px;
}
