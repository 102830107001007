.proteinCompare {
  padding: 40px !important;
  position: relative;
  border-radius: 10px;
}
.proteinCompare:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 45px 30px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  margin: auto;
}
.proteinCompareImage {
  display: block;
  width: 80%;
  max-width: 540px;
  margin: 0 auto;
}
.proteinMarkpen {
  color: #ff9b00;
}

@media screen and (max-width: 980px) {
  .proteinKvImage {
    width: 60vw;
  }
  .proteinCompare {
    padding: 30px 20px !important;
  }
  .proteinCompareImage {
    width: 100%;
  }
}
