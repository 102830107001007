.points {
  display: flex;
  gap: 28px;
  justify-content: center;
}
.point {
  border-radius: 10px;
  padding: 20px;
}
.wrap {
  display: none;
}
@media screen and (width <= 1035px) {
  .points {
    gap: 10px 20px;
    flex-wrap: wrap;
  }
  .wrap {
    display: block;
    width: 100%;
  }
}
.pointTitle {
  white-space: break-spaces;
}
