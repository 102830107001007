.root {
  display: table;
  width: 100%;

  li {
    display: table-cell;
    vertical-align: top;
    padding-bottom: 5px;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;

    &.active {
      border-bottom: 4px solid #fac83c;
    }
  }
}
