.root {
  width: 100%;
  position: relative;
}

.mainSwiper {
  height: 100%;
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  [class*="swiper-slide"] {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.counter {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  border-radius: 100px;
  padding: 5px 10px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.bullet {
  opacity: 1;
  width: 8px;
  height: 8px;
  margin: 8px 4px 4px;
  border-radius: 50%;
  display: inline-block;
  background: #c8c8c8 !important;
  cursor: pointer;

  &Active {
    background: #fac83c !important;
    cursor: default;
  }
}
