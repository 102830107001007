.proteinQa {
  display: table;
  width: 100%;
}
.proteinQa:nth-child(2n + 1) {
  margin-bottom: 15px;
}
.proteinQa:nth-child(2n + 1) dd {
  padding-top: 7px;
}
.proteinQa:nth-child(2n) {
  margin-bottom: 50px;
}
.proteinQa {
  dt,
  dd {
    display: table-cell;
    vertical-align: top;
  }
  dt {
    width: 50px;
  }
  dd {
    width: calc(100% - 50px);
  }
}
.proteinMarkpen {
  color: #ff9b00;
}
.image {
  display: inline-block;
  vertical-align: middle;
}
