.root {
  display: grid;
  box-sizing: border-box;
  overflow: scroll;

  // モバイル用スタイル
  @media (max-width: 1035px) {
    gap: var(--grid-gap-sp);

    &.rowFlowSp {
      grid-template-columns: repeat(var(--grid-cols-sp), 1fr);
      grid-auto-flow: row;
    }

    &.columnFlowSp {
      grid-template-rows: repeat(var(--grid-cols-sp), 1fr);
      grid-auto-flow: column;
    }
  }

  // PC用スタイル
  @media (min-width: 1036px) {
    gap: var(--grid-gap-pc);

    &.rowFlowPc {
      grid-template-columns: repeat(var(--grid-cols-pc), 1fr);
      grid-auto-flow: row;
    }

    &.columnFlowPc {
      grid-template-rows: repeat(var(--grid-cols-pc), 1fr);
      grid-auto-flow: column;
    }
  }
}
