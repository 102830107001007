.stampCard {
  border-radius: 10px;
  padding: 20px 10px 0;
  border: 1px solid #c8c8c8;
  position: relative;
  box-shadow: 5px 5px 0px 0px #828282;
}

.stampCircle {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  border: 1px dashed #5a5a5a;
  position: relative;
  text-align: center;
  padding-top: 8px;
  font-size: 24px;
  font-weight: bold;
}

.stampFill:after {
  content: "";
  position: absolute;
  left: -5px;
  top: -5px;
  width: 60px;
  height: 60px;
  background: url(https://asset.basefood.co.jp/images/parts/stamp.png) no-repeat center center;
  background-size: cover;
  z-index: 5;
}

.stamps {
  display: flex;
  align-items: flex-start;
  margin: auto;
  flex-wrap: wrap;
  max-width: 420px;
  @media screen and (max-width: 1036px) {
    display: flex;
    align-items: flex-start;
    max-width: 280px;
    margin: auto;
  }
}

.stamp {
  width: 60px;
  margin: 5px 5px 15px;
}

.stamp:last-child {
  margin-right: auto;
}

.flexSpace {
  width: 100%;
}
