.topPadding {
  @media screen and (max-width: 1035px) {
    padding-top: 20px;
  }
}

.heroImage {
  background:
    url(https://asset.basefood.co.jp/images/bg/bg_pointpack_pc.png) no-repeat center center,
    #f5d9ca;
  background-size: contain;
  height: 42.1875vw;
  margin-top: -57px;
}

@media (max-width: 1035px) {
  .heroImage {
    background:
      url(https://asset.basefood.co.jp/images/bg/bg_pointpack_sp.png) no-repeat center center,
      #f5d9ca;
    background-size: contain;
    height: 159vw;
    margin-top: 0;
  }
}
