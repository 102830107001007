.doctor {
  border-radius: 10px;
  border: 2px solid #f0f0f0;
  padding: 30px;
}

.markpen {
  color: #ff9b00;
}

.image {
  width: 100%;
  height: auto;
}
