.sectionHeader {
  align-items: center;
  justify-content: space-between;
}

.sectionBody {
  padding-top: 20px;
}

.borderTop {
  border-top: 1px solid #c8c8c8;
}
