.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 0;
  height: 0;
  position: relative;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #00508c;
}

.top {
  &Container {
    flex-direction: column;
  }
  &Arrow {
    transform: rotate(0deg);
    top: 1px;
  }
}
.bottom {
  &Container {
    flex-direction: column-reverse;
  }
  &Arrow {
    transform: rotate(180deg);
    bottom: 1px;
  }
}

.right {
  &Container {
    flex-direction: row-reverse;
  }
  &Arrow {
    transform: rotate(90deg);
    right: 4px;
  }
}

.left {
  &Container {
    flex-direction: row;
  }
  &Arrow {
    transform: rotate(-90deg);
    left: 4px;
  }
}
