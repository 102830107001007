.borderTop {
  border-top: 1px solid #c8c8c8;
}

.input {
  width: 100%;
}

.lineDescription {
  color: #9b9b9b;
  & > a {
    margin-left: 3px;
    border-bottom: 1px solid;
  }
}
