.root {
  background-color: #f0f0f0;
  @media (max-width: 1035px) {
    background-color: #fff;
  }
}

.container {
  border: 2px solid #fac83a;
  border-radius: 20px;
  background-color: #fff;
  padding: 40px;
  @media screen and (max-width: 1035px) {
    padding: 20px;
  }
}

.listItem {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;

  &:before {
    content: "●";
    font-size: 16px;
    color: #fac83a;
    position: absolute;
    left: 0;
    line-height: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1035px) {
    padding-left: 25px;
    &:before {
      left: 0;
    }
  }
}

.link {
  color: #fac83c !important;
  border-bottom: 1px solid !important;
}
