.root {
  padding-top: 5px;
  background-color: #cbe2b6;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 14px;
  padding-left: 14px;
  @media screen and (min-width: 1036px) {
    max-width: 1008px;
  }
  @media screen and (min-width: 1336px) {
    max-width: 1308px;
  }
}

.headerImage {
  background-image: url("/assets/images/mypage/kv_invite_friends_sp.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 160vw;

  @media (min-width: 1024px) {
    background-image: url("/assets/images/mypage/kv_invite_friends_pc.png");
    height: 42.1875vw;
    margin-top: -57px;
  }
}
