.ctaLink {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  width: calc(100% - 80px);
  margin: auto;
  z-index: 10;
  max-width: 300px;
}
