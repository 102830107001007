.root {
  display: flex;
}

.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 20px 0;
}

.body {
  display: flex;
}

.description {
  display: flex;
  margin-bottom: 20px;
}

.tableContainer {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #5a5a5a;
}

.table {
  border-collapse: collapse;
  width: 100%;
  // グローバルのスタイルを上書きする
  margin: 0 !important;
}

.tableHeader {
  background-color: #5a5a5a;
}

.tableHeaderCell {
  color: #ffffff;
  text-align: center;
  border-left: 1px solid #ffffff;
  &:first-child {
    border-left: none;
    width: 1%;
  }
}

.tableBodyRow {
  border-bottom: 1px solid #5a5a5a;
  &:last-child {
    border-bottom: none;
  }
}

.tableBodyCell {
  color: #5a5a5a;
  text-align: center;
  border-left: 1px solid #5a5a5a;
  vertical-align: middle;
  font-size: 16px;
  color: #5a5a5a;
  font-weight: 700;
  &:first-child {
    border-left: none;
    width: 1%;
  }
}

.rankTitleContainer {
  // SP用
  @media (max-width: 1035px) {
    padding: 0 20px;
    width: 144px;
  }

  // PC用
  @media (min-width: 1036px) {
    padding: 0 40px;
    width: 192px;
  }
}

.leverageTitle {
  white-space: pre-wrap;
  text-align: left;
  font-size: 12px;
}

.leverageRate {
  font-size: 24px;
  margin-right: 4px;
}
