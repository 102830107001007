.headerImage {
  height: auto;
  color: transparent;
  object-fit: cover;
  width: 100vw;
}
.kv {
  margin-bottom: -8px;
}
.cta {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
}
