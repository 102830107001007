.root {
  padding-top: 5px;
}

@media (max-width: 1036px) {
  .root {
    padding-top: 0;
  }
}

.heroImage {
  background: url(https://asset.basefood.co.jp/images/bg/fv_campaign_shopping_new_items_pc_0826.png)
    no-repeat center center;
  background-size: contain;
  height: 42.1875vw;
  margin-top: -57px;
}

@media (max-width: 1036px) {
  .heroImage {
    background: url(https://asset.basefood.co.jp/images/bg/fv_campaign_shopping_new_items_sp_0826.png)
      no-repeat center center;
    background-size: contain;
    height: 111vw;
    margin-top: 0;
  }
}

.breadcrumbText {
  color: #fff;
}

.targetItems {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 380px;
  margin: auto;
}

.flexSpace {
  width: 100%;
}

.item {
  text-align: center;
  width: 80px;
  margin-bottom: 20px;
  position: relative;
}

.stampClear {
  background: url(https://asset.basefood.co.jp/images/parts/img_stamps_clear.png) no-repeat center
    center;
  background-size: contain;
}
