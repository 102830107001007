.container {
  padding: 80px 0;
  position: relative;
  width: 100%;
  align-items: center;
  background-color: #00508c;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > h3 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 700;
  }
  & > h4 {
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
  }
}

.body {
  padding: 28px;
  align-items: center;
  gap: 40px;
}

.descriptionContainer {
  margin-top: -20px;
}

.description {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.imagesContainer {
  display: flex;

  // PC
  @media (min-width: 1036px) {
    flex-wrap: nowrap;
    gap: 10px;
  }

  // Mobile
  @media screen and (max-width: 1035px) {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    & > div {
      flex: 0 0 33%;
      box-sizing: border-box;
      align-items: center;
      margin-bottom: 12px;
    }
  }
}

.image {
  width: min(90px, 22.5vw);
  height: auto;
  border-radius: 10px;
}
