.memberVoice {
  background-color: #fff;
  border-radius: 10px;
  padding: 100px 40px 40px;
  position: relative;
}

.imageContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.description {
  font-size: 1rem;
}
