.overflowHidden {
  overflow: hidden;
}

.planTable {
  display: flex;
  width: 812px;
  justify-content: space-between;
  margin: 0 auto;
}

.plan {
  width: 392px;
  position: relative;
}

.planLink {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  bottom: 30px;
  width: fit-content;
}

.planSlide {
  position: relative;
}

.planSlideLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 32px;
  margin: auto !important;
  width: fit-content;
}

.dummyBlock {
  visibility: hidden;
}

.toggleBox {
  background-color: #fff;
  border-radius: 100px;
  padding: 5px;
  width: 100%;
  max-width: 319px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.toggleButton {
  display: block;
  width: 155px;
  border-radius: 100px;
  padding: 8px 13px 12px;
  cursor: pointer;
  transition: 0.3s ease all;

  &:first-child {
    color: #78b428;
  }

  &:last-child {
    color: #ff2850;
  }
}

.toggleOn {
  &:first-child {
    background-color: #78b428;
    color: #fff;
  }

  &:last-child {
    background-color: #ff2850;
    color: #fff;
  }
}

.swiperWrapper {
  width: 100vw;
  margin-left: -14px;
  margin-right: -14px;
}

.swiper {
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 28px;
}

.paginationBullet {
  opacity: 1;
  width: 8px;
  height: 8px;
  margin: 8px 4px 4px;
  border-radius: 50%;
  display: inline-block;
  background: #ffffff;
  cursor: pointer;

  &Active {
    background: #ff2850;
    cursor: default;
  }
}
