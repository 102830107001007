.scheduleTable {
  width: 100%;
  td {
    padding: 20px;
    vertical-align: middle;
  }
}
.scheduleTitle {
  background-color: #c8c8c8;
}
.scheduleSubTitle {
  background-color: #f0f0f0;
}
.scheduleFixWidth {
  width: 60px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.scheduleTopSolid {
  border-top: 1px solid #c8c8c8;
}
.schedulePayment {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.scheduleLeftDashed {
  border-left: 1px dashed #c8c8c8;
}
.scheduleContent {
  background-color: #fff;
}
.scheduleLeftSolid {
  border-left: 1px solid #c8c8c8;
}
