.title {
  text-align: center;
  font-size: 1.5rem;
  color: #5a5a5a;
  margin-top: 20px;
  @media (min-width: 1036px) {
    text-align: left;
  }
}

.deliveryTemperatureTags {
  .tag {
    &:first-child {
      margin-left: 0;
    }
  }
}

.checkoutTermsButton {
  text-decoration: underline;
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
}

.wwbw {
  word-wrap: break-word;
}

.bottomActions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottomButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
