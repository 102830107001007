@media screen and (max-width: 1036px) {
  .campaignSp {
    background-color: #fcfdef;
    margin-left: -28px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 40px;
    width: 100vw;
  }
}
