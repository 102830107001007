.container {
  padding: 80px 0;
  position: relative;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > h3 {
    font-size: 40px;
    color: #00508c;
    font-weight: 700;
  }
  & > h4 {
    font-size: 16px;
    color: #00508c;
    font-weight: 700;
  }
}

.body {
  padding: 28px;
  align-items: center;
  gap: 40px;
}

.description {
  color: #5a5a5a;
  font-weight: bold;
  text-align: center;
}

.flowImage {
  width: 100%;
  max-width: 320px;
}

.benefitImage {
  width: 100%;
  max-width: 476px;
}

.rankUpHintContainer {
  margin-top: 10px;
}

.rankUpHint {
  padding: 14px;
  border-radius: 4px;
}

.fullImages {
  width: 100%;
  height: auto;
}
