.yakisobaSection {
  margin-top: 40px;
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1036px) {
  .yakisobaSection {
    margin-top: 0;
    margin-bottom: 40px;
  }
}
