.proteinBack {
  min-height: 56.25vw;
}
.proteinBackLogo {
  margin-top: -20px;
}

@media screen and (max-width: 980px) {
  .proteinBack {
    min-height: 132.5vw;
  }
  .proteinBackLogo {
    margin-top: -50px;
  }
}
