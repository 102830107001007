.slideWrap {
  width: 100%;
  max-width: 750px;
  background: url(/assets/images/lp/yakisoba/slide_background.jpg) no-repeat center center;
  background-size: 100%;
  padding: 0 5% !important;
  margin-top: -8px;
}

.slide {
  width: 100%;
  max-width: 750px;
  text-align: center;
  padding: 0 5% 2%;
}

.bullet {
  opacity: 1;
  width: 8px;
  height: 8px;
  margin: 8px 8px 4px;
  border-radius: 50%;
  display: inline-block;
  background: #fff !important;
  cursor: pointer;

  &Active {
    background: #a13900 !important;
    cursor: default;
  }
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 40px;
  height: 70px;
  background-color: #a13900;
  &Prev {
    left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &Next {
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
