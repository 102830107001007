.processBase {
  width: 100%;
  position: relative;
  background-color: #f0f0f0;
  padding-left: 20px;
  padding-right: 20px;
  > div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
  }
  > span {
    position: relative;
    z-index: 5;
  }
}

.processUnfilled {
  > div {
    background-color: #fac83c;
  }
  > span {
    color: #5a5a5a;
  }

  > span > b {
    color: #ff6478;
    border-bottom: 1px solid #ff6478;
  }
  > span:last-of-type {
    margin-left: 20px;
    @media screen and (max-width: 480px) {
      display: block;
      margin-left: 0px;
    }
  }
}

.processFilled {
  > div {
    background-color: #ff6478;
  }
  > span {
    color: #fff;
  }
  > span > b {
    display: none;
  }
}
