.planDiscountNotice {
  font-weight: bold;
  margin: 0 auto;
  border: 2px solid #ff6478;
  color: #ff6478;
  padding: 20px;
  margin-bottom: 40px;
  display: flex;
  // PC
  @media (min-width: 1036px) {
    max-width: 476px;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    max-width: 319px;
  }
}
