.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 14px;
  padding-left: 14px;

  @media screen and (min-width: 1036px) {
    max-width: 1008px;
  }

  @media screen and (min-width: 1336px) {
    max-width: 1308px;
  }
}

@media screen and (max-width: 1035px) {
  .container {
    display: none;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerHome {
  display: block;
  padding: 12px 0 9px;
}

.navigation {
  display: block;
  padding: 0;
}

.navMenu {
  display: flex;
  align-items: center;
}

.navMenuCell {
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    @media screen and (min-width: 1036px) {
      .navLink {
        padding: 0 10px;
      }
    }

    @media screen and (min-width: 1336px) {
      .navLink {
        padding: 0 15px;
      }
    }
  }
}

.navLink {
  display: block;
  border: none;
  letter-spacing: 0.01em;
}

.navigationIcon {
  padding: 0 15px;
}

.navigationBorder {
  border-left: #c8c8c8 1px solid;
  border-right: #c8c8c8 1px solid;
  display: block;
}

.navigationIconAlign {
  vertical-align: middle;
}

@media screen and (max-width: 1036px) {
  .navigation {
    background-color: #fff;
    position: fixed;
    right: -80vw;
    top: 0;
    padding: 20px 28px 40px 40px;
    z-index: 100;
    transition: 0.3s ease right;
    width: 80vw;
    max-width: 300px;
    height: 100vh;
    overflow: scroll;

    &.active {
      right: 0;
    }
  }
}
