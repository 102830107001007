.input {
  display: block;
  padding: 9px;
  width: 100%;
  font-size: 16px;
  line-height: 1;
}
.inputError {
  border-color: #ff6478;
}
