.checkoutTerms {
  dl.checkout-term {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  dt.checkout-terms-title {
    position: relative;
    padding: 13px 15px;
    cursor: pointer;

    i {
      position: absolute;
      right: 13px;
      top: 15px;
    }
  }

  dd.checkout-terms-content {
    padding: 13px 15px;
    max-height: 60vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  @media screen and (max-width: 1036px) {
    padding-top: 80px;
  }
}
