.voice {
  background-color: #fff;
  padding: 80px 40px 40px;
  border-radius: 10px;
  margin: 0 10px;
  position: relative;
  height: 100%;
  @media screen and (max-width: 1036px) {
    margin: 65px 0 40px 0;
  }
}

.user {
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
  margin: auto;
  width: 130.5px;
  height: 120px;
}

.paginationBullet {
  opacity: 1;
  width: 8px;
  height: 8px;
  margin: 8px 4px 4px;
  border-radius: 50%;
  display: inline-block;
  background: #ffffff;
  cursor: pointer;

  &Active {
    background: #ff2850;
    cursor: default;
  }
}
