.root {
  background-color: #cee7ec;
}

.breadcrumb {
  display: flex;
  flex-direction: column;
}

.headerImage {
  height: auto;
  color: transparent;
  object-fit: cover;
  width: 100vw;
}
