.threePoints {
  border-radius: 20px;
  background-color: #fff;
  padding: 30px 20px;

  @media screen and (min-width: 1036px) {
    min-height: 310px;
    padding: 20px;
  }
}

.priceOutline {
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
}
.pricePercent {
  display: inline-block;
  margin-right: 5px;
  padding: 3px 6px;
  vertical-align: text-bottom;
}
.fullImages {
  width: 100%;
  height: auto;
}
.contentsSlider {
  padding-left: 20px;
  padding-right: 20px;
}
.swiperWrapper {
  margin-bottom: 40px;
}
