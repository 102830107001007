.modal {
  max-width: 400px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0;
  & > h3 {
    font-size: 20px;
    font-weight: 700;
  }
}

.body {
  align-items: center;
}

.giftTitle {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
}

.description {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.divider {
  width: 100%;
  margin: 20px 0 !important;
}

.mileUsage {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mileUsageTitle {
  flex: 1 1 auto;
  & > svg {
    margin-right: 10px;
  }
  & > span {
    font-weight: 700;
  }
}

.mileUsageDetail {
  flex: 2 2 auto;
  justify-content: space-around;
  align-items: center;
}

.mileUsageAfter {
  font-weight: 700;
}

.arrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fac83c;
}

.cation {
  font-size: 12px;
}
