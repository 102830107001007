.base {
  display: inline-block;
  vertical-align: middle;
  padding: 2px 7px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 700;
}

.yellow-filled {
  background-color: #fac83c;
  color: #5a5a5a;
}

.yellow-outlined {
  background-color: #fff;
  border: 1px solid #fac83c;
  color: #fac83c;
}

.red-filled {
  background-color: #ff6478;
  color: #fff;
}

.red-outlined {
  background-color: #fff;
  border: 1px solid #ff6478;
  color: #ff6478;
}

.green-filled {
  background-color: #47b881;
  color: #fff;
}

.green-outlined {
  background-color: #fff;
  border: 1px solid #47b881;
  color: #47b881;
}

.gray-filled {
  background-color: #f5f5f5;
  color: #5a5a5a;
}

.gray-outlined {
  background-color: #fff;
  border: 1px solid #5a5a5a;
  color: #5a5a5a;
}

.light-green-filled {
  background-color: #78b428;
  color: #fff;
}

.light-green-outlined {
  background-color: #fff;
  border: 1px solid #78b428;
  color: #78b428;
}

.darkblue-filled {
  background-color: #8cb4dc;
  color: #fff;
}

.darkblue-outlined {
  background-color: #fff;
  border: 1px solid #8cb4dc;
  color: #8cb4dc;
}
