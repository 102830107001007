.root {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c8c8c8;
}

.list {
  width: 100%;
  display: table;
  & > li {
    text-indent: 0;
    display: table-row;
    & > * {
      border-bottom: 1px solid #c8c8c8;
    }
    &:last-child {
      & > * {
        border-bottom: none;
      }
    }
  }
}
