.cell {
  display: table-cell;
  vertical-align: middle;
}

.headerCell {
  font-size: 0.75rem;
  padding: 10px 20px;
  white-space: nowrap;
}

.bodyCell {
  padding: 4px 20px;
  width: 100%;
  & > * {
    width: 100%;
  }
}
