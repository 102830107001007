.mainSwiper {
  height: 100%;
  width: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.thumbSwiperWrapper {
  margin: 0 -28px;
  padding: 0 28px;
}

.thumbSwiper {
  height: 10%;
  box-sizing: border-box;
  padding: 10px 6px;
  img {
    display: block;
    width: 71px;
    height: 71px;
    object-fit: cover;
    box-sizing: content-box;
  }
  [class*="swiper-slide-thumb-active"] {
    border: 2px solid #ff9b00;
  }
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 32px;
  height: 32px;
  opacity: 0.33;
  &Prev {
    left: 2%;
  }
  &Next {
    right: 2%;
  }
}
