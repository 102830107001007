.root {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  padding: 0;
  z-index: 99;
}

.inner {
  padding: 9px 0 0;
}
