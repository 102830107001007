.container {
  position: relative;
  width: 100%;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}

.inner {
  width: 100%;
  max-width: 640px;
}

.title {
  text-align: center;
}
