.container {
  border: 2px solid #fac83c;
  border-radius: 8px;
  padding: 20px;
  min-height: 136px;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}
