@media only screen and (min-width: 768px) {
  .wrap {
    gap: 28px;
    justify-content: center;
  }
  .cell {
    max-width: 180px !important;
  }
}
.productName {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 48px;
  &.singleLine {
    line-height: 48px;
  }
}
