.container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.detail {
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
}

.mile {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.iconContainer {
  display: flex;
  gap: 10px;
}

.icon {
  width: 20px;
}
