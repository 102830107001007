.subscriptionBox {
  height: 100%;
  padding: 40px 0;
  border-radius: 8px;
}
.subscriptionMerit {
  width: calc(100% - 40px);
  margin: 0 auto;
  max-width: 560px;
}
.subscriptionMeritDetail {
  display: table;
  width: 100%;
  dt,
  dd {
    display: table-cell;
    vertical-align: middle;
  }
  dt {
    width: 40px;
  }
  dd {
    width: calc(100% - 40px);
    border-left: 2px solid #fac83a;
    padding-left: 20px;
  }
}
.compareTablePc {
  width: 100%;
  td {
    vertical-align: middle;
    background-color: #f0f0f0;
    border: solid #fff;
    width: 25%;
  }
  tr:first-child td:first-child {
    background-color: #fff;
  }
  tr:nth-child(2) td {
    background-color: #fac83c;
  }
}
.compareTableSP {
  width: 100%;
  td {
    vertical-align: middle;
    background-color: #f0f0f0;
    border: solid #fff;
  }
  tr:first-child td:first-child {
    background-color: #fff;
  }
  td:first-child {
    width: 20%;
    padding: 5px;
  }
  td:nth-child(2) {
    background-color: #fac83c;
    width: 45%;
    padding: 15px 10px;
  }
  td:last-child {
    width: 35%;
    padding: 15px 10px;
  }
}
