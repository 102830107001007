.body {
  display: flex;
  gap: 28px;
  flex-direction: row;
  @media screen and (max-width: 1035px) {
    flex-direction: column;
    // container クラスの paddingをなくす
    margin-left: -28px;
    margin-right: -28px;
  }
}
