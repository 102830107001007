.root {
  background: url("/assets/images/lp/form01/bg_ingredients_pc.jpg") no-repeat 50%;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 15vw;
  padding-bottom: 15vw;
}

@media (max-width: 768px) {
  .root {
    background: url("/assets/images/lp/form01/bg_ingredients_sp.jpg") no-repeat bottom center;
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 100vw;
    position: relative;
  }
}
