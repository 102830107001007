.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.notes {
  font-size: 12px;
  line-height: 16px;
}
