.container {
  position: fixed;
  top: 54px;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #f0f0f0;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dummyHeight {
  height: 173px;
}

.fixedContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
  position: relative;
  display: block;
  overflow: hidden;
  height: 56px;
  transition: 0.3s ease all;

  span {
    position: absolute;
    top: 0;
    transition: 0.3s ease all;
  }
}

.hideTitle {
  height: 0;

  span {
    top: -56px;
  }
}

.totalPrice {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
