.wrapper {
  max-width: 750px;
  width: 100%;
}

.button {
  cursor: pointer;
}

.image {
  display: inline-block;
  vertical-align: middle;
}
