.table {
  width: 100%;
}

.head:first-child {
  text-align: center;
  width: 5.5em;
}
.head {
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px;
  border: 1px solid #c8c8c8;
}

.row:first-child {
  text-align: center;
  width: 5.5em;
}

.row {
  padding: 10px;
  border: 1px solid #c8c8c8;
}
