.alignCenter {
  -webkit-align-items: center;
  align-items: center;
}

.section {
  border-radius: 20px;
  background-color: #f0f0f0;
  padding: 30px 20px;

  @media screen and (min-width: 1036px) {
    padding: 40px 30px;
  }
}

.border {
  background: linear-gradient(transparent 50%, #fac83c 50%, #fac83c 80%, transparent 80%);
}
