.lineButton {
  // globalのa.hrefを強制上書き
  color: #fff !important;
  background-color: #06c755;
}
.lineIcon {
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 6px;
}
