.container {
  position: relative;
  width: 100%;
  height: 16px;
  border-radius: 9px;
}

.firstChild {
  position: absolute;
  left: 8px;
  top: 2px;
  line-height: 1;
}

.secondChild {
  position: absolute;
  right: 8px;
  top: 2px;
  line-height: 1;
}

.bar {
  line-height: 1;
  padding: 0 6px;
  border-radius: 9px;
  height: 100%;
}

.children {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}
