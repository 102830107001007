.root {
  background-color: #f0f0f0;
}

.shareUrl {
  font-size: 0.875rem; /* 14px */
  font-weight: bold;
  margin-top: 1.25rem; /* 20px */
}

.shareBox {
  background-color: #fac83c;
  display: block;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px !important;
}

.shareText {
  font-size: 1.25rem; /* 20px */
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 20px !important;
}

.shareDescription {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.shareImage {
  object-fit: contain;
  width: 100% !important;
  height: auto !important;
}

.shareTitle {
  color: #508c00;
}
