.wrapper {
  width: 100%;
}

.swiper {
  padding: 0 20px;
}

.toggle {
  background-color: #f0f0f0;
  border-radius: 42px;
  padding: 10px 5px 12px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}

.button {
  border-radius: 42px;
  padding: 10px 15px;
  cursor: pointer;
}

.active {
  background-color: #fac83c;
}
