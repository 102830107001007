.counterList {
  counter-reset: custom-counter;
  list-style: none;
  padding: 0px;

  li {
    counter-increment: custom-counter;
    position: relative;
    padding: 20px 0 20px 35px;
    margin-bottom: 10px;

    &::before {
      content: counter(custom-counter);
      position: absolute;
      left: 0;
      background: #5a5a5a;
      color: white;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50%;
    }
  }
}
.counterListBorderBottomDotted {
  border-bottom: 1px dotted #c8c8c8;
}
