.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100vh;
  border: none;
  background-color: rgba(230, 233, 236, 0.8);
  cursor: wait;
  opacity: 1;
  transition: opacity 0.4s ease 0s;
}

.modal {
  position: absolute;
  width: 320px;
  height: 135px;
  top: 50%;
  left: 50%;
  padding: 40px 60px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 100;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.progressText {
  font-weight: bold;
}
