.availableMileContainer {
  border-bottom: solid 1px #c8c8c8;
  border-radius: 4px 4px 0 0;
}

.availableMile {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: right;
}

.availableMileLink {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: right;
}

.totalMile {
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: right;
}

.tooltipIcon {
  color: #c8c8c8;
  cursor: pointer;
}

.leverageContainer {
  border-bottom: solid 1px #c8c8c8;
  border-radius: 4px 4px 0 0;
}

.leverage {
  font-size: 20px;
  font-weight: 700;
}

.leverageUnit {
  font-size: 16px;
  font-weight: 700;
  margin-left: 4px;
  line-height: 28px;
}

// theme.cssのCSSをoverrideするために、importantを使用
.toMileProgramLink {
  border-bottom: 1px solid #fac83c !important;
  color: #fac83c !important;
}
