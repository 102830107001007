.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  text-align: center;
}

.fadeOut {
  animation: fade-out 3s;
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}
