.recommendSection {
  align-items: center;
}

.productSubttl {
  padding-bottom: 10px;
  border-bottom: 2px solid #fac83a;
}

.recommendReason {
  position: relative;
  padding-left: 35px;
  padding-bottom: 5px;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    background-color: #fac83c;
    border-radius: 50%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 7px;
    height: 11px;
    transform: rotate(40deg);
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
  }
}
