.container {
  border-bottom: 1px solid #5a5a5a;
}

.description {
  &Text {
    > a {
      color: #fac83c !important;
      border-bottom: 1px solid;
    }
  }
}

.allergen {
  background-color: #707070;
  border: 2px solid #707070;

  > p:first-child {
    padding: 10px 0;
  }

  > p:last-child {
    background-color: #fff;
    padding: 10px;
  }
}

.accordionBody {
  padding: 15px 0px;
  & a {
    color: #fac83c !important;
    border-bottom: 1px solid;
  }
}
