.container {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.title {
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.amount {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: right;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #828282;
}
