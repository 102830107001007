.bgGreenKv {
  background-color: #cbe2b6;
}

.bgInvited {
  padding-top: 1px;
  height: 42.1875vw;
}
@media screen and (max-width: 1036px) {
  .bgInvited {
    height: 159.2vw;
  }
}

.icon {
  margin: 0 5px;
}
