.root {
  width: 100%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 4px;
}

.anchorMark {
  font-size: 20px;
  padding: 0px 5px;
}

.display {
  // src/style/style.scssに.toggle__cart p.text__red.text__s {text__s {display: none;}があるため。
  display: block !important;
}
