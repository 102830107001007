.root {
  display: flex;
  justify-content: space-between;
}

.box {
  background-color: #fff;
  border-radius: 4px;
  min-width: calc(33% - 8px);
}

.link {
  display: block;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 0.75rem; // for mobile
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    font-size: 1rem; // for desktop
  }
}

.icon {
  font-size: 2rem; // for mobile

  @media screen and (min-width: 1024px) {
    font-size: 3rem; // for desktop
  }
}

.label {
  padding-top: 5px;
}

.x {
  width: 28px;
  height: auto;
  display: inline;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    width: 42px;
  }
}

.facebook {
  color: #3b5998;
}

.line {
  color: #06c755;
}
