.content {
  border: 2px solid #fac83c;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
