.merit {
  padding: 40px;
  border-radius: 10px;
  position: relative;
  margin-top: 35px;
}

.number {
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  margin: auto;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #ff2850;
  border: 10px solid #fff;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}
