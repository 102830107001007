.footer {
  background-color: #5a5a5a;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;

  a,
  p {
    color: #fff;
  }

  &Sns {
    a {
      display: inline-block;
      vertical-align: top;
      font-size: 40px;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      line-height: 1;

      &:last-child {
        margin-right: 0;
      }

      path {
        fill: #fff;
      }
    }

    &Link {
      &Wide {
        width: 82px;
      }
    }
  }

  &Link {
    display: inline-block;
    border-bottom: 1px solid;
    margin-bottom: 10px;
    line-height: 1.2;
    font-weight: normal;
    @media screen and (max-width: 1036px) {
      display: flex;
      align-items: center;
      border-bottom: 0;
      border-top: 1px dashed;
      margin-bottom: 0;
      line-height: 64px;
      font-weight: bold;
      position: relative;
    }

    i {
      display: none;
      @media screen and (max-width: 1036px) {
        display: inline-block;
        margin-left: auto;
        margin-right: 10px;
      }
    }
  }

  &GoTop {
    display: block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    text-align: center;

    i {
      font-size: 44px;
    }
  }

  &Logo {
    display: inline-block;
    transition: 0.3s ease opacity;

    img {
      height: 44px;
      width: auto;
    }
  }

  &Category {
    margin-bottom: 10px;
    @media screen and (max-width: 1036px) {
      display: flex;
      align-items: center;
      border-top: 1px solid;
      line-height: 64px;
      margin-bottom: 0;
      &.footerCategoryBottom {
        border-bottom: 1px solid #fff;
      }
    }

    i {
      display: none;
      @media screen and (max-width: 1036px) {
        display: inline-block;
        margin-left: auto;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 1036px) {
  .footer {
    &Items {
      display: none;
      padding-left: 20px;

      &.footerItemsBottom {
        border-bottom: 1px solid #fff;
      }

      &.on {
        display: block;
      }
    }
  }
}
