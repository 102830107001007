@mixin spotlight {
  width: 2px;
  margin: 15px auto;
}

.spotlightLeft {
  @include spotlight;
  transform: rotate(var(--gradient-left));
  margin-right: 10px;
}

.spotlightRight {
  @include spotlight;
  transform: rotate(var(--gradient-right));
  margin-left: 10px;
}
