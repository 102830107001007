.titleContainer {
  border-top: 1px solid #5a5a5a;
  padding: 15px 0px;
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.iconOpen {
  width: 12px;
  height: 12px;
  display: block;
  margin-right: 24px;
}

.iconClose {
  width: 12px;
  height: 2px;
  display: block;
  margin-right: 30px;
}

.body {
  overflow: hidden;
}

.bodyClose {
  display: none;
  height: 0px;
}

.bodyOpen {
  display: block;
  border-top: 1px dashed #c8c8c8;
}
