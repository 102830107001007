.wrapper {
  margin: 0 4px;
}

.item {
  text-align: center;
  width: 80px;
  margin-bottom: 20px;
  position: relative;
}

.imageContainer {
  padding-bottom: 5px;

  &.purchasedIcon::after {
    content: "";
    background: url(https://asset.basefood.co.jp/images/parts/purchased.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    width: 52px;
    height: 24px;
    display: block;
  }
}

.purchasedImageContainer {
  opacity: 0.3;
}

.purchasedImage {
  opacity: 0.3;
}

.title {
  font-size: 12px;
}
