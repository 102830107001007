.productNav {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #fac83c;
  }
}

.list {
  display: flex;
  margin-bottom: 10px;
}

.item {
  margin-right: 5px;
  max-width: 120px;
  &:last-child {
    margin-right: 0;
  }
}

.link {
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 4px;
  display: block;
  height: 100%;
  @media screen and (max-width: 1036px) {
    padding: 10px 6px;
    min-width: 100px;
  }
}

.selected {
  border: 4px solid #fac83a;
  padding: 1px;
  @media screen and (max-width: 1036px) {
    padding: 6px 2px;
  }
}

.strikethroughPrice {
  margin-right: 4px;
  display: inline-block;
}

.priceLabel {
  white-space: nowrap;
  display: inline-block;
}

.showDiscountPrice {
  padding: 10px 5px;
  min-width: 120px;
}
