.title {
  text-align: center;
  font-size: 1.5rem;
  color: #5a5a5a;
  margin-top: 20px;
  @media (min-width: 1036px) {
    text-align: left;
  }
}

.unfill {
  display: inline-block;
  vertical-align: middle;
  background-color: #ff6478;
  padding: 2px 7px;
  border-radius: 5px;
  margin-right: 5px;
}

.normal {
  height: 230px;
  overflow: hidden;
  position: relative;
}

.processFixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 10000;
}

.hideForm {
  z-index: 1000;
  position: relative;
  margin-top: -190px;
  margin-bottom: 220px;
}

@media screen and (min-width: 1036px) {
  .reverse {
    -webkit-box-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.loaderContainer {
  & {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px); // Headerとの高さを引いた高さ
    @media screen and (min-width: 1036px) {
      height: calc(100vh - 100px); // Headerとパンくずリストの高さを引いた高さ
    }
  }
}
