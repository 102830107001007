.block {
  padding: 40px;
  border-radius: 40px;
  margin-left: -28px;
  width: 100vw;
}
.list {
  border-bottom: 1px solid #c8c8c8;
  &:last-child {
    border-bottom-width: 0;
  }
}
