.sectionAmazonPay {
  border: 1px solid #fac83c;
  padding: 30px 20px;
  border-radius: 13px;
}

.tagAmazon {
  margin-right: 5px;
}

.titleBorder {
  border-bottom: 1px solid #c8c8c8;
}
