.btn.inline {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

.buttonIcon {
  margin-left: 5px;
}

.couponContainer {
  align-items: center;
  flex-wrap: nowrap;
  & > input {
    background-color: #ffffff;
    margin-right: 8px;
  }
}
.mypageBordertop {
  border-top: 1px solid #c8c8c8;
}
.mypageBordertopDashed {
  border-top: 1px dashed #c8c8c8;
}
.categoryContainer {
  padding: 20px 0;
}

.totalpriceTable {
  display: table;
  width: 100%;
  dt,
  dd {
    display: table-cell;
    vertical-align: text-bottom;
  }
}
.cartHasNormalProducts {
  border: 2px solid #fac83c;
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
  .cartTemperatureTitle {
    background-color: #fac83c;
  }
}
.cartHasFreezedProducts {
  border: 2px solid #8cb4dc;
  padding: 0 20px 20px;
  border-radius: 5px;
  overflow: hidden;
  .cartTemperatureTitle {
    background-color: #8cb4dc;
  }
}
.cartTemperatureTitle {
  margin-left: -20px;
  margin-right: -20px;
  padding: 10px 20px;
}
.firsttime {
  display: block;
  margin-bottom: -30px;
}
.mypageSubscriptionProducts {
  margin-right: -28px;
  margin-left: -28px;
  overflow: scroll;
  ul {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 20px;
    li {
      position: relative;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
        padding-right: 20px;
        span {
          right: 12px;
        }
      }
      img {
        width: 55px;
        height: 55px;
      }
      span {
        position: absolute;
        right: -8px;
        bottom: 0;
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        white-space: nowrap;
        background-color: #5a5a5a;
        line-height: 24px;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.planDiscountStatusCardContainer {
  padding: 20px 14px;
}

.pcPlanDiscountStatusCard {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.applyingPlanLabel {
  color: #78b428;
}
