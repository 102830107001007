.reason {
  border-radius: 10px;
  padding: 40px;
}

@media screen and (max-width: 980px) {
  .reason {
    padding: 30px 20px;
  }
}

.markpen {
  color: #ff9b00;
}

.image {
  width: 100%;
  height: auto;
}

.reason__image {
  margin-left: auto;
  margin-right: auto;
  max-width: 580px;
}

.graph__image {
  max-width: 400px;
}
