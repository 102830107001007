.root {
  position: relative;
}
.header {
  position: relative;
  padding-bottom: 20px;
  white-space: pre-wrap;
}
.headerNew {
  position: relative;
  white-space: pre-wrap;
}
.body {
  position: relative;
  margin: auto;
  width: 100%;
}

.title {
  padding-bottom: 10px;
  border-bottom: 2px solid #fac83c;
}
