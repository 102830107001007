.root {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 4px;
  @media screen and (min-width: 1036px) {
    padding: 15px;
  }
}

.toggleSection {
  display: table;
  width: 100%;
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 15px;
  @media screen and (max-width: 1036px) {
    padding-bottom: 20px;
  }
}

.description {
  display: table-cell;
  vertical-align: middle;
  width: calc(100% - 62px);
}

.toggle {
  display: table-cell;
  vertical-align: middle;
  width: 62px;
}
