.title {
  display: inline-block;
  width: 160px;
  text-align: center;
  line-height: 52px;
  border-radius: 52px;
  &.sender {
    background-color: #ff9b00;
  }
  &.receiver {
    background-color: #ff6478;
  }
}
.step {
  padding: 40px 40px 0;
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  &.sender {
    background-color: #fef4d8;
  }
  &.receiver {
    background-color: #ffe0e4;
  }
}
.stepTitle {
  white-space: pre-wrap;
}
.stepDetail {
  white-space: pre-wrap;
  &.sender {
    min-height: 6.5em;
  }
  &.receiver {
    min-height: 5em;
  }
}
.stepImage {
  margin-bottom: -7px;
  margin-top: 20px;
  max-width: 250px;
}
.number {
  display: inline-block;
  width: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  &.sender {
    background-color: #ff9b00;
  }
  &.receiver {
    background-color: #ff6478;
  }
}
.slideWrap {
  margin-left: -28px;
  width: 100vw;
}
.swiperWrapper {
  padding-left: 28px !important;
  padding-right: 28px !important;
}
.clearSpFlex {
  display: none !important;
}
@media screen and (width >= 1036px) {
  .clearSpFlex {
    display: flex !important;
  }
}
.swiper-button-prev {
  left: -15px !important;
  background-color: #ff6478;
}

.swiper-button-next {
  right: -15px !important;
}
