.formSection {
  width: 100%;
  @media screen and (min-width: 1036px) {
    padding-bottom: 60px;
  }
}

.formWrapper {
  padding: 40px 28px 0;

  @media screen and (min-width: 1036px) {
    border-radius: 20px;
    max-width: 1008px;
    margin: 0 auto;
    padding: 40px 175px 0;
  }

  @media screen and (min-width: 1336px) {
    max-width: 1308px;
  }
}

.fixedFormWrapper {
  padding: 40px 28px 0;

  @media screen and (min-width: 1036px) {
    max-width: 750px;
    margin: 0 auto;
  }
}
