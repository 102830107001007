.root {
  width: 100%;
}

.mypageFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mypageBordertop {
  border-top: 1px solid #c8c8c8;
}

.modalDefaultButton {
  display: inline-block;
  margin: 0.5em 0.4em 0.5em 0;
  padding: 0.4em 1em;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
  background: #f6f6f6;
  cursor: pointer;
  color: #454545;
  font-size: 16px;
  &:hover {
    border: 1px solid #cccccc;
    background: #ededed;
    color: #2b2b2b;
  }
}

.radioInput + label {
  display: block;
}
.cardDetail {
  margin-left: 32px;
}
.cardIcon {
  height: 24px;
  width: auto;
}

.bottomActionsContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  background-color: #fff;
  border-top: 1px solid #c8c8c8;
  z-index: 100;
  transition: transform 0.3s;
}

.bottomActionsHidden {
  transform: translateY(100%);
}

.bottomInnerContainer {
  padding: 8px 0px;

  & > button {
    margin: 0 6px;
  }

  & {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1036px) {
    justify-content: center;
  }
}
.textLink {
  border-bottom: 1px solid;
}

.myToastedContainer {
  top: -35px !important;
}

.buttonStartIcon {
  margin-right: 8px;
}

.paymentMethodSeparator {
  margin: 20px 0 !important;
}

.sectionsContainer {
  display: flex;
  flex-wrap: wrap;

  // 親のpaddingをmarginで打ち消す
  margin-left: -14px;
  margin-right: -14px;
  @media screen and (max-width: 1036px) {
    margin: 0;
  }
}

.sectionHeader {
  align-items: center;
  justify-content: space-between;
}

.sectionBody {
  padding-top: 20px;
}

.addressSections {
  // PCだと2列、SPだと1列にする
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 28px;
  @media screen and (max-width: 1036px) {
    grid-template-columns: 1fr;
  }
}

.radioButtons {
  margin-bottom: 0px;
  transition: border-radius 0.5s ease;
}

.radioButtonsExtended {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.radioButton {
  padding: 12px 20px;
}

.billingAddressForm {
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: 500px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top: none !important;
}

.billingAddressFormHidden {
  max-height: 0;
  border-bottom: none !important;
}
