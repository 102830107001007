.container {
  border-bottom: 1px dashed #c8c8c8;
  &:last-child {
    border-bottom: none;
  }
}

.chip {
  margin-right: 5px;
}
