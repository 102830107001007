.itemsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 1036px) {
    grid-template-columns: 1fr;
  }
}

.itemContainer {
  width: 100%;
  padding: 14px;
}
