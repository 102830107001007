.idealNutritionalBlock {
  border-radius: 8px;
  padding: 20px;
  min-height: 136px;
}

.description {
  @media screen and (max-width: 749px) {
    font-size: 0.9375em;
  }
}
