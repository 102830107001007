.marginAdjust {
  margin-top: 10px !important;
}
.marginAdjustIndex {
  margin-top: 20px !important;
}
@media screen and (max-width: 1036px) {
  .marginAdjust,
  .marginAdjustIndex {
    margin-top: 40px !important;
  }
}
