// import, use用の共通CSS

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.flex {
  &-1 {
    flex: 1;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
