.footerLink {
  text-align: center;
  margin-top: 4em;
  margin-bottom: 2em;
}

.link {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.copy {
  border-top: 1em solid #f7cf51;
  text-align: center;
  font-size: 0.75em;
  box-sizing: border-box;
  padding: 0.5em;
}

.image {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 2px;
}
