.history {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}
.history:nth-child(2n + 1) {
  background-color: #fff;
}
.history:nth-child(2n) {
  background-color: #f0f0f0;
}
.date {
  display: inline-block;
  margin-right: 10px;
}
@media screen and (max-width: 1036px) {
  .history {
    padding: 20px;
  }
  .date {
    display: block;
  }
}
