.container {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #f0f0f0;
}

.title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  // PC
  @media (min-width: 1035px) {
    font-size: 1.2rem;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    font-size: 0.9rem;
  }
}

.titlesContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
}

.card {
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  align-items: center;
  justify-content: center;

  // PC
  @media (min-width: 1035px) {
    width: 70%;
    max-width: 600px;
    height: 40%;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    width: 80%;
    max-width: 600px;
    height: 60%;
  }
}

.actions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  // PC
  @media (min-width: 1035px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  // Mobile
  @media screen and (max-width: 1035px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
