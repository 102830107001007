.root {
  background: url(https://asset.basefood.co.jp/images/parts/lp_bg_sample.png) no-repeat right 5%;
  background-size: 219px 250px;
}

@media screen and (max-width: 980px) {
  .root {
    background: url(https://asset.basefood.co.jp/images/parts/lp_bg_sample.png) no-repeat right 98%;
    background-size: 90px 100px;
  }
}

.paragraph {
  padding: 20px 0;
}
