.description {
  border-radius: 20px;
  background-color: #f0f0f0;
  padding: 30px 20px;
}

.border {
  background: linear-gradient(transparent 50%, #fac83c 50%, #fac83c 80%, transparent 80%);
}

@media screen and (min-width: 1036px) {
  .description {
    padding: 40px 30px;
  }
}
