.switch {
  text-align: right;
}

@media screen and (max-width: 1036px) {
  .switch {
    text-align: left;
  }
}

.slide {
  position: relative;
  display: block;
  overflow: hidden;
  height: fit-content;
}

.togglePanel {
  margin-top: 0;
  transition: 0.3s ease all;
}

.togglePanelUp {
  margin-top: -100%;
  transition: 0.3s ease all;
}
