.root {
  color: #00508c;
  border: 1px solid #00508c;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  min-width: 300px;
  border-radius: 4px;
}

.title {
  flex: 1 1 0;
  text-align: left;
  color: #00508c;
}

.icon {
  width: 24px;
  height: 24px;
  background-color: #00508c;
  border-radius: 50%;
  position: relative;
}

.icon::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}
