.inviteHeader {
  padding: 20px;
  border-radius: 10px;
}
.marginAdjust {
  margin-top: 10px !important;
}
@media screen and (max-width: 1036px) {
  .marginAdjust {
    margin-top: 40px !important;
  }
}
.campaignTitle {
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 16px;
    background-color: #ff2850;
    transform: rotate(-15deg);
    margin-right: 5px;
    vertical-align: text-bottom;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 2px;
    height: 20px;
    background-color: #ff2850;
    transform: rotate(15deg);
    margin-left: 5px;
    vertical-align: text-bottom;
  }
}
