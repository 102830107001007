.wrapper {
  max-width: 750px;
  width: 100%;
  margin-bottom: -8px;
}

.image {
  display: inline-block;
  vertical-align: middle;
}
