.root {
  position: relative;
  min-height: 80vh;
}

.heroImage {
  @media screen and (max-width: 980px) {
    object-position: calc(50% - 100px) 50%;
  }
}

.noEvents {
  pointer-events: none;
}

.noEventsWithpadding {
  padding: 0 18px;
}

@media screen and (min-width: 1036px) {
  .goodDesignContainer {
    justify-content: flex-end;
  }
}

.goodDesignContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
