.balance {
  &Content {
    border: 2px solid #fac83c;
    border-radius: 20px;
    padding: 20px;
    height: 100%;

    .text__l {
      white-space: pre-wrap;
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
}
