.shareBox {
  border-radius: 10px;
  padding: 40px 20px;
}

.image {
  max-width: 476px;
  margin: -100px auto 20px;
  display: block;
}

.note {
  border: 2px solid #fac83a;
  border-radius: 20px;
  background-color: #fff;
  padding: 40px;
  @media (max-width: 1036px) {
    padding: 20px;
  }
}

.textLink {
  color: #fac83c !important;
  font-size: 12px;
  border-bottom: 1px solid;
}

.noteItem {
  margin-bottom: 12px;
  padding-left: 40px;
  position: relative;
  @media (max-width: 1036px) {
    padding-left: 25px;
  }
}

.noteItem:before {
  content: "●";
  font-size: 16px;
  color: #fac83a;
  position: absolute;
  left: 10px;
  line-height: 24px;
  @media (max-width: 1036px) {
    left: 0px;
  }
}
