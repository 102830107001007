@use "../../../../style/rankColor.variables.scss" as rankColor;

.mileUserDefinition {
  display: flex;
  align-items: center;
}

.mileUserDescription {
  padding-left: 10px;
}

.mileAmount {
  margin-left: 5px;
}

.dashedLine {
  border-left: 1px dashed #c8c8c8;
}

.rankDetailsContainer {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1036px) {
  .rankDetailsContainer {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 0;
  }
  .dashedLine {
    border-left: none;
    border-top: 1px dashed #c8c8c8;
    width: 100%;
  }
}

.rankImg {
  margin-bottom: -6px;
}

.Economy {
  color: rankColor.$economy-primary;
}

.Business {
  color: rankColor.$business-primary;
}

.First {
  color: rankColor.$first-primary;
}

.Vip {
  color: rankColor.$vip-primary;
}
