.flavorLineup {
  display: flex;
  flex-wrap: wrap;
}
.flavorLineup dl {
  width: 20%;
  margin: 20px 10px;
}
.flavorLineup dl dt img {
  width: 100%;
  height: auto;
}
.flavorLineup dl dt {
  position: relative;
  .tag {
    position: absolute;
    left: 0;
    top: 0;
  }
  .mini {
    line-height: 24px;
    border-radius: 24px;
    font-size: 0.75rem;
    padding: 0 10px;
  }
  .square {
    line-height: 24px;
    border-radius: 5px;
    font-size: 0.75rem;
    padding: 0 10px;
  }
}
.flavorLineup dl dd {
  padding-top: 20px;
}
.flavorLineup div {
  width: 100%;
}
@media screen and (max-width: 980px) {
  .flavorLineup {
    margin-top: 20px;
  }
  .flavorLineup dl {
    width: 100%;
    margin: 20px 0;
    display: flex;
  }
  .flavorLineup dl dt {
    width: 40%;
  }
  .flavorLineup dl dd {
    width: 60%;
    padding-top: 0;
    padding-left: 20px;
  }
}

.hideInSP {
  @media screen and (max-width: 1035px) {
    display: none;
  }
}

.hideInPC {
  @media screen and (min-width: 1036px) {
    display: none;
  }
}
