.wrapper {
  position: relative;
  width: 100%;
  text-align: center;
}

.cvButton {
  position: absolute;
  bottom: 3%;
  left: 10%;
  right: 10%;

  &:hover {
    cursor: pointer;
  }
}
