.container {
  background-color: #ffffff;
  border-radius: 4px;
}

.padding- {
  &none {
    padding: 0;
  }

  &responsive {
    padding: 40px;
    width: 100%;

    @media screen and (max-width: 1035px) {
      padding: 20px 28px;
    }
  }

  &fixedSp {
    padding: 20px 28px;
  }

  &fixed20 {
    padding: 20px;
  }

  &fixed40 {
    padding: 40px;
  }
}
