.container {
  padding: 40px 0 0;
  position: relative;
  width: 100%;
  max-width: 476px;
  margin: 0 auto;
  background-color: #ffffff;
  @media screen and (max-width: 1035px) {
    padding: 40px 28px 0;
  }
}

.fullImages {
  width: 100%;
  height: auto;
}
