.discountPlanCheckoutGuide {
  padding: 20px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  // Mobile
  @media screen and (max-width: 1035px) {
    p {
      white-space: pre-line;
    }
  }
}
