.container {
  width: 100%;
  display: table;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
}

.input {
  border: none;
  outline: none !important;
  font-size: 1rem;
}
