.product {
  &Gift {
    border-radius: 4px;
    padding: 20px;
    &List {
      li {
        list-style: disc;
        margin-left: 1em;
      }
    }
  }
}
