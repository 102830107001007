.box {
  margin-top: 0.5rem; /* 8px */
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.text {
  width: 100%;
  margin-right: 10px;
  overflow: scroll;
}

.url {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
  .copied {
    color: #10b981;
  }
}

.copyButton {
  padding-left: 0.5rem;
  width: 40px;
  text-align: right;
  border-left: 1px solid #c8c8c8;
  cursor: pointer;
}
