.errors {
  padding: 8px;
  margin: 0 0 27.5px;
  border: 1px solid #d20000;
  background-color: #fff8f8;
  color: #d20000;
}

@media only screen and (min-width: 750px) {
  .errors {
    padding: 10px;
  }
}
